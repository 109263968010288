import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestBase } from '../services/request-base';
import { PoliticaPrivacidadeExibir } from './politica-privacidade-exibir';

@Injectable({
  providedIn: 'root'
})
export class PoliticaPrivacidadeExibirService extends RequestBase<PoliticaPrivacidadeExibir> {
  protected path = 'politica-de-privacidade';

  public constructor(http: HttpClient) {
    super(http)
  }
}
