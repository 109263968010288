import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from '../services/spinner/spinner.service';
import { AlertifyService } from '../services/alertify/alertify.service';
import { HttpClient } from '@angular/common/http';
import { RequestBase } from '../services/request-base';
import { SolicitarExclusaoUsuario } from './solicitar-exclusao-usuario';

@Injectable({
  providedIn: 'root'
})
export class SolicitarExclusaoUsuarioService extends RequestBase<SolicitarExclusaoUsuario> {
  protected path = 'solicitar-exclusao-usuario';
  constructor(
    http: HttpClient
  ) {
    super(http)
  }
}
