import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SolicitarExclusaoUsuarioService } from './solicitar-exclusao-usuario.service';
import { SolicitarExclusaoUsuario } from './solicitar-exclusao-usuario';
import { AlertifyService } from '../services/alertify/alertify.service';

@Component({
  selector: 'app-solicitar-exclusao-usuario',
  templateUrl: './solicitar-exclusao-usuario.component.html',
  styleUrls: ['./solicitar-exclusao-usuario.component.scss']
})
export class SolicitarExclusaoUsuarioComponent implements OnInit {
  form: any;

  constructor(
    private service: SolicitarExclusaoUsuarioService,
    private alertify: AlertifyService,
  ) { }

  ngOnInit() {

  }
  submit(form) {
    if (form.valid) {
      let msg = 'Você está solicitando a exclusão de seus dados. Deseja continuar?';
      msg += '<br/><br/>Ao continuar você confirma que está ciente que após a exclusão de seus dados você não terá mais acesso ao aplicativo nem ao histórico de orçamentos gerados durante o seu uso.';
      this.alertify.confirm(msg, () => {
          this.solicitacaoExclusaoUsuario(form);
      })
    }
  }


  solicitacaoExclusaoUsuario(form) {
      this.service.post(<SolicitarExclusaoUsuario> form.value)
      .subscribe((data) => {
        form.reset();
        this.alertify.success("Solicitação realizada com sucesso!")
      }, (error) => {
        this.alertify.error("Falha ao realizar a solicitação de exclusão. Tente novamente!");
        console.log(error)
      })
  }

  mask:string;

  cpfcnpjmask(e) {
    console.log(e.target.value.length);
    let qtdCaracteres = e.target.value.length;
    if(qtdCaracteres <= 14) {
      this.mask = '000.000.000-009';
    } else {
      this.mask = '00.000.000/0000-00';
    }
  }
}

