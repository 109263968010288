import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolicitarExclusaoUsuarioComponent } from './solicitar-exclusao-usuario.component';
import { MzInputModule, MzCardModule, MzIconMdiModule, MzButtonModule } from 'ngx-materialize';
import { FormsModule } from '@angular/forms';
import { SolicitarExclusaoUsuarioService } from './solicitar-exclusao-usuario.service';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  declarations: [SolicitarExclusaoUsuarioComponent],
  imports: [
    CommonModule,
    FormsModule,
    MzInputModule,
    MzCardModule,
    MzIconMdiModule,
    MzButtonModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
      HttpClientModule,
      SolicitarExclusaoUsuarioService
  ]
})
export class SolicitarExclusaoUsuarioModule { }
