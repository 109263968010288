import { Component, OnInit } from '@angular/core';
import { PoliticaPrivacidadeExibirService } from './politica-privacidade-exibir.service';


@Component({
  selector: 'app-politica-privacidade-exibir',
  templateUrl: './politica-privacidade-exibir.component.html',
  styleUrls: ['./politica-privacidade-exibir.component.scss']
})
export class PoliticaPrivacidadeExibirComponent implements OnInit {

  public textoPoliticaPrivacidade: string;

  constructor(
    private service: PoliticaPrivacidadeExibirService
  ) { }

  ngOnInit() {
    this.service.get().subscribe((data: any) => {
      this.textoPoliticaPrivacidade = data[0].politica_privacidade;
      }, (error) => {
        console.log('ERROR: ',error);
      });
  }

}
