import { Injectable } from '@angular/core';
declare let jQuery;

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private $ = jQuery;
  private spinner: any;
  constructor() { }

  public appendIn(element) {
    this.spinner = $('<div>')
      .addClass('container-spinner')
      .append(
        $('<div>')
          .addClass("lds-ring")
          .append($('<div>'))
          .append($('<div>'))
          .append($('<div>'))
          .append($('<div>'))
      );

    $(element).css({position: 'relative'}).append(this.spinner)

    this.spinner.css({opacity: 1})
  }

  public remove() {
    this.spinner.css({opacity: 0})
    setTimeout(() => {
      this.spinner.remove()
    }, 500)
  }
}
