import { SolicitarExclusaoUsuarioModule } from './solicitar-exclusao-usuario/solicitar-exclusao-usuario.module';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localePt from '@angular/common/locales/pt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { AlertifyService } from './services/alertify/alertify.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { MzValidationModule } from 'ngx-materialize';
import { NgxMaskModule } from 'ngx-mask';
import { PoliticaPrivacidadeExibirComponent } from './politica-privacidade-exibir/politica-privacidade-exibir.component';



registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    PoliticaPrivacidadeExibirComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MzValidationModule,
    LoginModule,
    AppRoutingModule,
    SolicitarExclusaoUsuarioModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: LocationStrategy, useClass: HashLocationStrategy},
    AlertifyService,
    SpinnerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
