import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PoliticaPrivacidadeExibirComponent } from './politica-privacidade-exibir/politica-privacidade-exibir.component';
import { SolicitarExclusaoUsuarioComponent } from './solicitar-exclusao-usuario/solicitar-exclusao-usuario.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },{
    path: 'politica-de-privacidade',
    component: PoliticaPrivacidadeExibirComponent
  },{
    path: 'solicitar-exclusao-usuario',
    component: SolicitarExclusaoUsuarioComponent
  },
  {
    path: '',
    loadChildren: 'src/app/dashboard/dashboard.module#DashboardModule'
  }, {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
