import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private service: LoginService
  ) { }

  ngOnInit() {
    localStorage.removeItem('token');
  }

  public login(form: NgForm) {
    console.log(form);
    if (form.valid) {
      this.service.login(form.value);
    }
  }

  tipoCampoSenha: boolean;

  /*Altera o tipo de campo senha para exibir ou n�o a senha*/
  exibirSenha() {
    this.tipoCampoSenha = !this.tipoCampoSenha;
  }

}
