import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MzInputModule, MzCardModule, MzIconMdiModule, MzButtonModule } from 'ngx-materialize';

import { LoginComponent } from './login.component';
import { FormsModule } from '@angular/forms';
import { LoginService } from './login.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    exports: [
        LoginComponent
    ],
    declarations: [
        LoginComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MzInputModule,
        MzCardModule,
        MzIconMdiModule,
        MzButtonModule
    ],
    providers: [
        HttpClientModule,
        LoginService
    ]
})
export class LoginModule { }
