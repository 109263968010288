import { Page } from "../models/page";

// export const base_url: string = 'http://192.168.7.203/sites/_www.app-distribuidor.com.br/_www.painel-app.excelenciago.com.br/excelencia-api/public/';
// export const base_url: string = 'http://187.72.231.85:8081/sites/app/excelenciago/api/public/';
// export const base_url = 'http://187.72.231.85:8081/sites/app/base_teste/app/excelenciago/excelencia-api/public/';
export const base_url: string = 'https://api.excelenciago.com.br/';
export const storage_keys = {
    token: 'token',
    user : 'user'
}
export const exclude_intercept = [
    'viacep.com.br',
];

export let pagination = <Page<any>>{ per_page: 10, data: [], total: 1 }
