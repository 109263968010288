import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { RequestBase } from '../services/request-base';
import { storage_keys } from '../config/global';
import { SpinnerService } from '../services/spinner/spinner.service';
import { AlertifyService } from '../services/alertify/alertify.service';
import { User } from '../dashboard/user/user';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends RequestBase<any> {

  constructor(
    http: HttpClient,
    private router: Router,
    private spinner: SpinnerService,
    private alertify: AlertifyService
  ) { 
    super(http)
  }

  protected path = 'user'

  public login(login) {
    this.spinner.appendIn('app-login');
    this.post(login, 'auth')
      .subscribe((data: {token: string, user: User}) => {
        this.spinner.remove()
        if (data && data.user && data.user.is_admin) {
          localStorage.setItem(storage_keys.token, data.token);
          localStorage.setItem(storage_keys.user, JSON.stringify(data.user));
          this.router.navigate(['/home']);
        } else {
          this.alertify.alert("Você não possui acesso a esse recurso");
        }
      }, (error) => {
        this.spinner.remove()
        this.alertify.alert("Você não possui acesso a esse recurso");
        console.log(error);
      })
  }
}
