import { Injectable } from '@angular/core';

declare let alertify: any

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor() {
    alertify.dialog('alert').set({transition:'zoom',message: 'Transition effect: zoom'});
    alertify.dialog('confirm').set({transition:'zoom',message: 'Transition effect: zoom'});
  }
  confirm(
    message: string,
    okCallback: () => any = () => { },
    cancelCallback: () => any = () => { }
  ) {
    alertify.confirm('Atenção!', message, okCallback, cancelCallback)
  }
  alert(message, callback: () => any = () => { }) {
    alertify.alert('Atenção!', message, callback)
  }
  success(message: string) {
    alertify.success(message);
  }
  error(message: string) {
    alertify.error(message);
  }
  warning(message: string) {
    alertify.warning(message);
  }
  message(message: string) {
    alertify.message(message);
  }
}
